import { Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import {faPlus, faXmark} from '@fortawesome/pro-duotone-svg-icons';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'isp-chips-input',
  templateUrl: './isp-chips-input.component.html',
  styleUrls: ['./isp-chips-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IspChipsInputComponent),
    multi: true
  }]
})
export class IspChipsInputComponent implements ControlValueAccessor {
  get getPlaceHolder(): string {
    return (this.disabled ? (this.selectedItems.length > 0 ? '' : this.emptyItemsPlaceholder) : this.placeholder);
  }

  get isReadOnly(): boolean {
    return (this.itemsList && this.itemsList.length > 0 && !this.customChips);
  }

  @Input() disabled: boolean = false;
  @Input() itemsList: any[] = [];  // List of items for dropdown
  @Input() displayProperty: string;  // Property to display for suggestions
  @Input() placeholder: string;  // Placeholder text
  @Input() customChips: boolean = false;  // Allow custom chips creation
  @Input() selectedItems: any[] = [];  // Initially provided selected items
  private initialSelectedItems: any[] = [];  // Stores initial items for comparison
  newlyAddedItems: any[] = [];

  @Input() removable: boolean = true;  // Allow chips to be removable
  @Input() emptyItemsPlaceholder: string;
  @Input() selectable: boolean = false;  // Allow selection of chips
  @Input() multiselect: boolean = false;  // Allow multiple chip selection

  @Output() itemSelected = new EventEmitter<{ selectedItem: any, selectedItemsList: any[] }>();
  @Output() itemRemoved = new EventEmitter<{ removedItem: any, selectedItemsList: any[] }>();
  @Output() chipSelected = new EventEmitter<any[]>();

  protected readonly faXmark = faXmark;
  protected readonly faPlus = faPlus;
  inputValue: string = '';
  filteredSuggestions: any[] = [];
  isSuggestionsVisible: boolean = false;
  showAbove: boolean = false;
  selectedChips: any[] = [];

  // ControlValueAccessor methods
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(value: any[]): void {
    this.initialSelectedItems = value || [];  // Store initial selected items
    this.selectedItems = [...this.initialSelectedItems];
    if (!value) {
      this.inputValue = null;
    }
    this.newlyAddedItems = [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectChip(item: any) {
    if (this.selectable && this.disabled) {
      const index = this.selectedChips.indexOf(item);
      if (index > -1) {
        this.selectedChips.splice(index, 1);  // Deselect chip
      } else {
        if (!this.multiselect) {
          this.selectedChips = [];  // Clear previous selections if not multiselect
        }
        this.selectedChips.push(item);  // Select chip
      }
      this.chipSelected.emit(this.selectedChips);  // Emit selected chips
    }
  }

  get isChipSelected() {
    return (item: any) => this.selectedChips.includes(item);  // Check if chip is selected
  }

  showSuggestions() {
    if (this.itemsList && this.itemsList.length > 0 && !this.customChips) {
      this.isSuggestionsVisible = true;
      this.filteredSuggestions = this.itemsList;
      this.checkDropdownPosition();
    } else {
      this.isSuggestionsVisible = false;
    }
  }

  checkDropdownPosition() {
    const inputElement = document.querySelector('.chip-list input') as HTMLElement;
    const inputRect = inputElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const spaceBelow = windowHeight - inputRect.bottom;
    this.showAbove = spaceBelow < 150;
  }

  onInputBlur() {
    setTimeout(() => {
      this.onTouched();
    }, 200);
  }

  addChip(value: any) {
    let chipValue = value;

    if (this.customChips) {
      chipValue = typeof value === 'string' ? { [this.displayProperty]: value } : value;
    }

    const exists = this.selectedItems.some(item => item[this.displayProperty] === chipValue[this.displayProperty]);

    if (chipValue && !exists) {
      this.selectedItems.push(chipValue);

      // Check if it's a new item (not part of the initial selected items)
      const isNewItem = !this.initialSelectedItems.some(item => item[this.displayProperty] === chipValue[this.displayProperty]);

      if (isNewItem) {
        // Add the new chip to `newlyAddedItems` and emit only those
        this.newlyAddedItems.push(chipValue);

        this.onChange([...this.newlyAddedItems]);  // Emit only newly added items
        this.itemSelected.emit({ selectedItem: chipValue, selectedItemsList: [...this.selectedItems] });
      }
    }

    // Reset input and suggestions
    this.inputValue = '';
    this.filteredSuggestions = [];
    this.isSuggestionsVisible = false;
  }

  handleCustomChips(event: KeyboardEvent) {
    if (this.customChips && event.key === 'Enter' && this.inputValue.trim()) {
      this.addChip(this.inputValue.trim());
      event.preventDefault();
    }
  }

  selectSuggestion($event: MouseEvent, suggestion: any) {
    $event.stopPropagation();
    if (!this.isSuggestionSelected(suggestion)) {
      this.addChip(suggestion);
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.chip-input-container') && !target.closest('.suggestions-list')) {
      this.isSuggestionsVisible = false;
    }
  }

  filterSuggestions() {
    const input = this.inputValue.toLowerCase();
    this.filteredSuggestions = this.itemsList
      .filter(suggestion =>
        suggestion[this.displayProperty].toLowerCase().includes(input) &&
        !this.selectedItems.includes(suggestion)
      );
  }

  removeChip(index: number) {
    const removedItem = this.selectedItems.splice(index, 1)[0];

    // Also remove from newlyAddedItems if it exists there
    const newItemIndex = this.newlyAddedItems.findIndex(item => item[this.displayProperty] === removedItem[this.displayProperty]);
    if (newItemIndex > -1) {
      this.newlyAddedItems.splice(newItemIndex, 1);  // Remove from newly added items
    }

    // Emit the updated list of newly added items
    this.onChange([...this.newlyAddedItems]);
    this.itemRemoved.emit({ removedItem, selectedItemsList: [...this.selectedItems] });

    // Refill suggestions if needed
    this.filterSuggestions();
  }

  isSuggestionSelected(suggestion: any): boolean {
    return this.selectedItems.some(item => item[this.displayProperty] === suggestion[this.displayProperty]);
  }
}
