import {animate, group, keyframes, query, style, transition, trigger, useAnimation} from '@angular/animations';
import {Component, OnInit} from '@angular/core';
import {fadeInAnimation} from '../../../shared/animation/common.animation';
import {EventBusService} from '../../../shared/utils/event-bus.service';
import {AuthService} from '../../services/auth-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('newNotification', [
      transition(':enter', [
        group([
          useAnimation(fadeInAnimation),
          query('i.fa', [
            animate('.4s .1s', keyframes([
              style({transform: 'rotate(15deg)'}),
              style({transform: 'rotate(-15deg)'}),
              style({transform: 'rotate(15deg)'}),
              style({transform: 'rotate(-15deg)'}),
              style({transform: 'rotate(0)'})
            ]))
          ], {optional: true})
        ])

      ])
    ])
  ]
})
export class HeaderComponent implements OnInit {
  username: string;

  constructor(private authService: AuthService,
              private eventBusService: EventBusService) {
  }

  ngOnInit(): void {
    this.authService.getUsername().subscribe(value => {
      if (value) {
        this.username = value;
      }
    });
  }

  logout(): void {
    this.eventBusService.clearEvents();
    this.authService.logout();
  }
}
