<div class="chip-input-container">
  <div class="chip-list" [class.border-none]="disabled">
    <div *ngFor="let item of selectedItems; let i = index" class="chip" [class.cursor-pointer]="selectable && disabled" [class.selected]="isChipSelected(item)" (click)="selectChip(item)">
      {{ item[displayProperty] }}
      <span class="clear-icon" *ngIf="removable || !item.fixed" (click)="removeChip(i)" (keydown)="removeChip(i)">
        <fa-icon [icon]="faXmark"></fa-icon>
      </span>
    </div>

    <!-- Wrapper for input and button -->
    <div class="isp-chips-input-wrapper">
      <input
        class="isp-chips-input"
        type="text"
        [placeholder]="getPlaceHolder | translate"
        [(ngModel)]="inputValue"
        (input)="filterSuggestions()"
        (keydown.enter)="handleCustomChips($event)"
        (focus)="showSuggestions()"
        (blur)="onInputBlur()"
        [disabled]="disabled"
        [readonly]="isReadOnly"
      />

      <!-- Icon button next to text -->
      <button type="button" *ngIf="inputValue" class="isp-chips-add-button" (click)="addChip(inputValue)">
        <fa-icon [icon]="faPlus"></fa-icon>
      </button>
    </div>
  </div>
  <ul class="suggestions-list" [ngClass]="{'show-above': showAbove}" *ngIf="isSuggestionsVisible" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <li *ngFor="let suggestion of filteredSuggestions" (click)="selectSuggestion($event, suggestion)" [ngClass]="{'disabled': isSuggestionSelected(suggestion)}">
      {{ suggestion[displayProperty] }}
    </li>
  </ul>
</div>
