import {ConfigurationsTaxModel} from './ConfigurationsTaxModel';

export class CompanyTaxesPostModel {
  taxGroupId: string;
  numbers: TaxNumber[];
}

export class TaxNumber {
  taxTypeId: string;
  number: string;
}

export class CompanyTaxesGetModel {
  configId: string;
  taxGroup: ConfigurationsTaxModel;
  numbers: TaxNumber[];
}
